@import '../../config/styles/common.scss';

.section {
    margin-bottom: 10px;
}

// CONTENT
.section-content {
    display: flex;
    flex-direction: row;
    overflow: auto;
}

// CONTENT ITEM
.section-item:hover{
    box-shadow: 4px 4px 8px #c2c1be;
}
.section-item {
    display: flex;    
    flex: 1 1 0;
    flex-direction: column;    
    justify-content: space-between;
    //background-color: $bg-dark-color;
    background-color: $light-color;
    margin-top: 0px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    min-height: 100px;
    min-width: 167.5px;
    padding: 10px 8px 10px 8px;
    border-radius: 4px;
    transition: box-shadow .3s;
    box-shadow: 3px 3px 2px #c2c1be;
}
.section-item:first-child {
    margin-left: 0px;
}
.section-item:last-child {
    margin-right: 0px;
}

.home-card-icon {
    //color: $primary-light-color!important;
    color: $primary-color!important;
}

.section-item-headar-text {
    font-size: 19px!important;
    font-weight: 600;
    //color: $light-color!important;
    color: $secondary-color!important;
}

a.section-item-headar-link  {
    text-decoration: none;
}

.section-item-overflow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px;
    border-radius: 4px;
    background-color: #c2c1be;
    opacity: 0.3;
    margin-top: -32px;
}
.section-item-overflow-hidden {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px;
    border-radius: 4px;
    background-color: transparent;
    opacity: 0.3;
    margin-top: -15px;
}

@-webkit-keyframes action {
    0% { transform: translateX(7); }
    100% { transform: translateX(-5px); }
}
@keyframes action {
    0% { transform: translateX(7px); }
    100% { transform: translateX(-5px); }
}

.section-item-overflow-content {
    padding: 0px;
    margin: 0px;
    -webkit-animation: action 1s infinite  alternate;
    animation: action 1s infinite  alternate;
}

.section-item-body {
    padding-top: 15px;
    padding-bottom: 15px;
    // color: $light-color!important;
    color: $secondary-color!important;
}

a.section-item-footer-link  {
    text-decoration: none;
}

.section-item-footer-text {
    font-size: 18px!important;
    font-weight: 500;
    // color: $light-color!important;
    color: $secondary-color!important;
}