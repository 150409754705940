@import '../../../../config/styles/common.scss';

.dose-flow {
    color: $primary-color;
    font-size: 25px; 
    font-weight: bold;
}

.flow-dose {
    color: $primary-color;
    font-size: 25px; 
    font-weight: bold;
}

.drug-type {
    font-size: 20px !important;

}

#toggle-mode-2,
#toggle-mode-1  {
  padding-left: 0px!important;
  padding-right: 0px;
}

.toggle-icon {
  font-size: 12px!important;
}