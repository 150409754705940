@import '../../config/styles/common.scss';

.img-app {
    border-radius: 5px;
    height: 80px;
    width: 80px;
    margin-top: 13px;
}

.mr-tree-light {
    color: $primary-color;
}

.mr-tree-light:hover {
    color: $primary-color;
    opacity: 0.95;
    text-decoration: none;
}

.mr-tree-dark {
    color: $text-strong-color;
}

.mr-tree-dark:hover {
    color: $text-strong-color;
    opacity: 0.95;
    text-decoration: none;
}

