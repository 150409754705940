@import '../../../config/styles/common.scss';

.title-container-mr {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    justify-items: center;
    margin-bottom: 5px;
}

.button-back-mr {
    cursor: pointer;
    color: $secondary-color;
    font-size: 33px !important;
}

.title-separator {
    font-size: 13px !important;
    margin-left: 5px;
    margin-right: 5px;
}

.center-mr {
    /* flex: 5; */
    padding-left: 15px;
    padding-right: 15px;
}

.subtitle-mr {
    font-size: 1rem;
}