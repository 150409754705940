@import "../../../../config/styles/theme.scss";

$radius-value: 7px;

.card-drug {
  display: flex;
  flex-direction: column;
  border: 0px;
  padding: 0px;
  height: 100%;
  min-height: 152px;
  border-radius: $radius-value;
  background-color: $light-color;
  align-items: center;
  transition: box-shadow 0.3s;
  box-shadow: 4px 4px 4px #959595;
}

.card-drug:hover {
  box-shadow: 4px 4px 8px #5a5a5a;
}

.card-drug-header {
  padding: 10px 10px 0px 10px;
  text-align: center;
}

.card-drug-header h5 {
  margin-bottom: 0px;
}

.card-drug-link {
  text-decoration: none !important;
  font-size: 18px;
  font-weight: 500;

  &--light,
  &--light:hover {
    color: $secondary-color;
  }
  &--dark,
  &--dark:hover {
    color: $light-color;
  }
}

.card-drug-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  justify-content: center;
}

.card-drug-footer {
  padding: 5px 10px 5px 10px;
  width: 100%;
  text-align: center;
  color: white;
  background-image: linear-gradient(to bottom right, #ef4444, #c80d05);
  border-bottom-left-radius: $radius-value;
  border-bottom-right-radius: $radius-value;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-text-mr {
  font-size: 14.5px;
}
