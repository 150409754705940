@import "./theme.scss";
@import "./pages/app-store.scss";

/* Geral */

.app-bg {
    &--light {
        background-color: #e6e6e6!important;
    }
    &--dark {
        background-color: #343a40!important;
    }
}

nav.bg-dark {
    background-color: #1c1c1c !important;
}

button:not(.btn-social-network-dark).btn-dark {
    background-color: #1c1c1c !important;
    border-color: #1c1c1c !important;
}

/* About Page */
button.btn-social-network-light {
    background-color: #ffffff !important;
    border-color: #ffffff !important;
}

.icon-social-network-light {
    color: #1c1c1c !important;
}

/* Section component*/
.section-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

// Section Header text
@mixin section-header-text {
    display: flex;
    flex-direction: row;
    font-size: 26px !important;
}
.section-header-text {
    &--dark {
        @include section-header-text();
        color: $light-color !important;
    }
    &--light {
        @include section-header-text();
        color: $secondary-color !important;
    }
}

// Header Text Dark and Light
// .section-header-text-dark {
//     display: flex;
//     flex-direction: row;
//     font-size: 26px !important;
//     color: $secondary-color !important;
// }
// .section-header-text-light {
//     display: flex;
//     flex-direction: row;
//     font-size: 26px !important;
//     color: $light-color !important;
// }

// Header Subtext Dark and Light
.section-header-subtext-dark {
    font-size: 20px !important;
    font-weight: lighter;
    color: $secondary-color !important;
    align-self: center;
}

.section-header-rule {
    width: 33px;
    height: 3px;
    background-color: $primary-color !important;
    margin-left: 0px;
    margin-top: 0px;
}

/* Calculadora de drogas */
.type-icon {
    font-size: 13px !important;
    margin-right: 5px;
    color: $primary-color !important;
}

.type-text {
    font-size: 20px !important;
    color: $secondary-color !important;
}

.type-container {
    padding-bottom: 15px;
    display: flex;
    align-items: center;
}

/*Cacluladoras de VM*/
.list-group-mr .list-group-item {
    background-color: #343a40 !important;
    color: #ffffff !important;
    //border-color: #343a40 !important;
}

.list-group-mr .list-group-item:hover {
    background-color: #6c757d !important;
    //border-color: #6c757d !important;
}

/*Textos*/
.text-danger-dark {
    color: $primary-light-color !important;
}
.text-danger-light {
    color: $primary-color !important;
}
